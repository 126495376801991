import { ComponentSetType } from 'modules/componentSets/types';
import { CategoryType } from 'modules/categories/types';
import { TeamType } from 'modules/teams/types';

export type ProjectsStoreType = {
  map: Record<string, NormalizedProjectType>;
  addProjectUserErrorMessage: string;
  addProjectUserSuccessfulMessage: string;
  getProjectErrorCode: number | null;
  currentProject: string | null;
  createProjectSelectedTeam: string | null;
};

export type InviteType = {
  uuid: string;
  invitedEmail: string;
};

export type ProjectType = {
  id: number;
  uuid: string;
  name: string;
  componentSets: ComponentSetType[];
  stylesheet: string;
  lastUpdate: Date;
  stylesheetPath: string;
  targetTechnology: TargetTechnologyEnum;
  uiFramework: UIFrameworkEnum;
  designTool: DesignToolEnum;
  betaUser: boolean;
  contributors: ProjectContributorType[];
  projectInvites?: InviteType[];
  categories: CategoryType[];
  team: TeamType | null;
};

export type NormalizedProjectType = Omit<ProjectType, 'componentSets' | 'categories'> & {
  componentSets: string[];
  categories: string[];
};

export type ProjectContributorType = {
  uuid: string;
  email: string;
  role: 'owner' | 'contributor';
};

export enum TargetTechnologyEnum {
  REACT = 'react',
  VUE = 'vue',
  HTML = 'html',
  WEWEB = 'weweb',
  PRISMIC = 'prismic',
}

export enum UIFrameworkEnum {
  CSS = 'css',
  SCSS = 'scss',
  STYLED_COMPONENTS = 'styledComponents',
  JSON = 'json',
}

export enum DesignToolEnum {
  SKETCH = 'sketch',
  FIGMA = 'figma',
  ADOBE_XD = 'adobeXD',
}
