import React from 'react';
import styles from './ProjectSettings.module.scss';
import Button from 'components/Atoms/Button';
import FlatButton from 'components/Atoms/FlatButton';
import InputField from 'components/Atoms/InputField';
import { CircularProgress } from '@material-ui/core';
import { TargetTechnologyEnum, UIFrameworkEnum } from 'modules/projects/types';
import { updateSettingsRequestCreator } from 'modules/projects';

export interface IProjectSettingsProps {
  updateSettings: typeof updateSettingsRequestCreator;
  isLoading: boolean;
  projectId: string;
  formerStylesheetPath: string;
  targetTechnology: TargetTechnologyEnum;
  UIFramework: UIFrameworkEnum;
}

type State = {
  stylesheetPath: string;
};

const availableUIFramework: Record<TargetTechnologyEnum, UIFrameworkEnum[]> = {
  [TargetTechnologyEnum.REACT]: [UIFrameworkEnum.SCSS, UIFrameworkEnum.STYLED_COMPONENTS],
  [TargetTechnologyEnum.VUE]: [UIFrameworkEnum.SCSS],
  [TargetTechnologyEnum.WEWEB]: [UIFrameworkEnum.SCSS],
  [TargetTechnologyEnum.PRISMIC]: [UIFrameworkEnum.JSON],
  [TargetTechnologyEnum.HTML]: [UIFrameworkEnum.CSS],
};

class ProjectSettings extends React.Component<IProjectSettingsProps, State> {
  constructor(props: IProjectSettingsProps) {
    super(props);
    this.state = {
      stylesheetPath: this.props.formerStylesheetPath,
    };
  }

  changeInputStylesheetPath = (e: any) => {
    this.setState({ stylesheetPath: e.target.value.replace(/['"]+/g, '') });
  };

  submitStylesheetPath = () => {
    const { stylesheetPath } = this.state;
    if (!stylesheetPath) {
      return;
    }
    this.props.updateSettings({
      projectUuid: this.props.projectId,
      stylesheetPath,
      uiFramework: this.props.UIFramework,
      targetTechnology: this.props.targetTechnology,
    });
  };

  onUIFrameworkSelect = (uiFramework: UIFrameworkEnum) => () => {
    if (this.props.UIFramework !== uiFramework) {
      this.props.updateSettings({
        projectUuid: this.props.projectId,
        stylesheetPath: this.props.formerStylesheetPath,
        uiFramework,
        targetTechnology: this.props.targetTechnology,
      });
    }
  };

  render() {
    return (
      <div className={styles.mainContainer}>
        <div className={styles.stylesheetConfigContainer}>
          <div className={styles.inputContainer}>
            <InputField
              label={'Stylesheet path in project'}
              type={''}
              value={this.state.stylesheetPath}
              placeholder={'ex: /src/stylesheet.scss'}
              onChange={this.changeInputStylesheetPath}
              required
            />
            <div className={styles.stylesheetPathButtonContainer}>
              <Button
                type="submit"
                onClick={this.submitStylesheetPath}
                isLoading={this.props.isLoading}
              >
                Submit Path
              </Button>
            </div>
          </div>
          <div>
            <p className={styles.label}>Frontend framework</p>
            <div className={styles.inputContainer}>
              {this.props.targetTechnology === TargetTechnologyEnum.REACT && (
                <FlatButton
                  className={styles.flatButton}
                  isSelected={this.props.targetTechnology === TargetTechnologyEnum.REACT}
                  theme={'react'}
                >
                  React
                </FlatButton>
              )}
              {this.props.targetTechnology === TargetTechnologyEnum.VUE && (
                <FlatButton
                  className={styles.flatButton}
                  isSelected={this.props.targetTechnology === TargetTechnologyEnum.VUE}
                  theme={'vue'}
                >
                  Vue
                </FlatButton>
              )}
              {this.props.targetTechnology === TargetTechnologyEnum.WEWEB && (
                <FlatButton
                  className={styles.flatButton}
                  isSelected={this.props.targetTechnology === TargetTechnologyEnum.WEWEB}
                  theme={'vue'}
                >
                  WeWeb
                </FlatButton>
              )}
              {this.props.targetTechnology === TargetTechnologyEnum.HTML && (
                <FlatButton
                  className={styles.flatButton}
                  isSelected={this.props.targetTechnology === TargetTechnologyEnum.HTML}
                  theme={'html'}
                >
                  HTML/CSS
                </FlatButton>
              )}
              {this.props.isLoading && (
                <CircularProgress className={styles.technologyLoader} size={24} />
              )}
            </div>
          </div>
          <p className={styles.label}>UI framework</p>
          <div className={styles.inputContainer}>
            {availableUIFramework[this.props.targetTechnology].includes(UIFrameworkEnum.SCSS) && (
              <FlatButton
                className={styles.flatButton}
                onClick={this.onUIFrameworkSelect(UIFrameworkEnum.SCSS)}
                isSelected={this.props.UIFramework === UIFrameworkEnum.SCSS}
                theme={'scss'}
              >
                SCSS
              </FlatButton>
            )}
            {availableUIFramework[this.props.targetTechnology].includes(
              UIFrameworkEnum.STYLED_COMPONENTS,
            ) && (
              <FlatButton
                className={styles.flatButton}
                onClick={this.onUIFrameworkSelect(UIFrameworkEnum.STYLED_COMPONENTS)}
                isSelected={this.props.UIFramework === UIFrameworkEnum.STYLED_COMPONENTS}
                theme={'styledComponents'}
              >
                Styled Components
              </FlatButton>
            )}
            {availableUIFramework[this.props.targetTechnology].includes(UIFrameworkEnum.CSS) && (
              <FlatButton
                className={styles.flatButton}
                onClick={this.onUIFrameworkSelect(UIFrameworkEnum.CSS)}
                isSelected={this.props.UIFramework === UIFrameworkEnum.CSS}
                theme={'css'}
              >
                CSS
              </FlatButton>
            )}
            {this.props.isLoading && (
              <CircularProgress className={styles.technologyLoader} size={24} />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ProjectSettings;
