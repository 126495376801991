// @ts-nocheck
import React, { useState } from 'react';
import Refractor from 'react-refractor';
import './theme.scss';
import CopyToClipboard from 'react-copy-to-clipboard';
import { ReactComponent as StylesheetIcon } from 'assets/stylesheet-icon.svg';
import style from './CodeBox.module.scss';
import { Link } from 'react-router-dom';

// Load any languages you want to use from `refractor`
import js from 'refractor/lang/javascript';
import css from 'refractor/lang/css';
import scss from 'refractor/lang/scss';
import jsx from 'refractor/lang/jsx';
import markup from 'refractor/lang/markup';
import json from 'refractor/lang/json';
import LinearLoader from 'components/Atoms/LinearLoader';

Refractor.registerLanguage(js);
Refractor.registerLanguage(scss);
Refractor.registerLanguage(css);
Refractor.registerLanguage(jsx);
Refractor.registerLanguage(markup);
Refractor.registerLanguage(json);

interface IProps {
  code: string;
  language: string;
  isLoading: boolean;
  stylesheetLink?: string;
}

const CodeBox: React.FunctionComponent<IProps> = ({
  stylesheetLink,
  code,
  language,
  isLoading,
}) => {
  const initialCopyCodeText = 'Copy';
  const [textInCopyBox, changeTextInCopyBox] = useState(initialCopyCodeText);

  const onClick = () => {
    changeTextInCopyBox('Copied');
    setTimeout(() => {
      changeTextInCopyBox(initialCopyCodeText);
    }, 2000);
  };

  return (
    <>
      <div className={style.codeBoxContainer}>
        <Refractor language={language} value={code} className={style.codeBox} />
      </div>
      {stylesheetLink && (
        <Link className={style.stylesheetButton} to={stylesheetLink}>
          <StylesheetIcon className={style.stylesheetIcon} />
        </Link>
      )}
      <CopyToClipboard text={code}>
        <div className={style.copyButton} onClick={onClick}>
          {isLoading ? (
            <LinearLoader size={40} />
          ) : (
            <p className={style.buttonTitle}>{textInCopyBox}</p>
          )}
        </div>
      </CopyToClipboard>
    </>
  );
};

export default CodeBox;
